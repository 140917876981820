/**
 * Card Slider
 * @description : Swiper for the related show cards etc..
 */

(function () {

    var cardSliderEls = document.querySelectorAll('.js-card-slider');

    cardSliderEls.forEach(function (cardSliderEl) {

        var isLargeFormat = cardSliderEl.classList.contains('js-card-slider--lg-format');

        var swiperEl = cardSliderEl.querySelector('.js-card-slider__swiper');

        if (swiperEl) {
            var scrollbarEl = cardSliderEl.querySelector('.js-card-slider__scrollbar');
            new Swiper(swiperEl, {
                spaceBetween: 22,
                slidesPerView: 1.5,
                direction: 'horizontal',
                loop: false,
                watchSlidesVisibility: true,
                scrollbar: {
                    el: scrollbarEl,
                    draggable: true,
                    dragClass: 'js-card-slider__scrollbar-drag',
                    dragSize: 100,
                },
                breakpoints: {
                    [window.breakpoints['sm']]: {
                        freeMode: true,
                        freeModeMomentumRatio: 0.3,
                        scrollbar: {
                            dragSize: 200
                        },
                        slidesPerView: isLargeFormat ? 1.5 : 2
                    },
                    [window.breakpoints['md']]: {
                        scrollbar: {
                            dragSize: 400
                        },
                        spaceBetween: 26,
                        slidesPerView: isLargeFormat ? 2 : 2.5
                    },
                    [window.breakpoints['lg']]: {
                        spaceBetween: 34,
                        slidesPerView: isLargeFormat ? 3 : 4
                    },
                    [window.breakpoints['xl']]: {
                        spaceBetween: 46,
                        slidesPerView: isLargeFormat ? 3.5 : 4.5
                    }
                }
            });
        }

    });
})();